<template>
    <div id="main">
      <Nav-bar/>
      <transition name="router-anim">
      <router-view/>
    </transition>
      <FooterComponent/>
    </div>
</template>


<script>
import NavBar from '@/components/homeEng/HeaderComponent.vue'
import FooterComponent from '@/components/homeEng/Footer.vue'

export default {
    name: "HomeView",
    components: {
      NavBar,
      FooterComponent
    }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
  a{
    text-decoration: none;
 }

 li{
  list-style: none;
 }
 #main{
  background: linear-gradient(90deg, rgba(240, 240, 250, 0.5) 0%, #f2f2fc 50.17%, rgba(245, 245, 254, 0.5) 100%);

  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: bold; -webkit-font-weight: bold;
    /* animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 3px;
    line-height: 1.8;
    font-size: 18px;
    color:rgb(145, 145, 145); */
 }
/* MAIN CSS */
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  /* line-height: 1.45;
  font-weight: normal;
  text-decoration: none;
  border-bottom: 0px;
  color: #000; */
 }

 .router-anim-enter-active{
  animation: coming 1.5s;
  animation-delay: 0.3s;
  opacity: 0;
 }
 .router-anim-leave-active{
  animation: going 0.2s;
 }
 @keyframes going{
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
 }
 @keyframes coming{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
 }
</style>