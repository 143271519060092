<template>
   <center>
      <v-card  class="mb-4 cardUp">
         <v-btn to="/admin/nekretnine" fab class="mr-3 mb-3" color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn>
         <h4 class="mb-3 ml-12"  >Nova nekretnina</h4>
         </v-card>
    <v-card class="form">
      <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <v-text-field
  v-model="data.ownerNumber"
  :counter="30"
  label="Broj vlasnika"
  required
></v-text-field>
    <v-text-field
      v-model="data.name"
      :counter="80"
      :rules="nameRules"
      label="Ime"
      placeholder="Na primer: Prodaja, 54m2 , Zabjelo..."
      required
    ></v-text-field>
    <v-text-field
      v-model="data.nameEng"
      :counter="80"
      label="Ime engleski"
      placeholder="Na primer: Selling, 54m2 , Zabjelo..."
      required
    ></v-text-field>
    <v-text-field
      v-model="data.nameRus"
      :counter="80"
      label="Ime ruski"
      placeholder="Na primer: Квартира в белый день..."
      required
    ></v-text-field>

   
    <v-select
      v-model="data.town"
      :items="townSelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Grad"
      required
    ></v-select>
    <v-select
      v-model="data.purpose"
      :items="purposeSelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Svrha"
      required
    ></v-select>
    <v-select
      v-model="data.category"
      :items="categorySelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Kategorija"
      required
    ></v-select>
    <v-text-field
      v-model="data.bathroomsNum"
      :counter="30"
      label="Broj kupatila"
      required
    ></v-text-field>
    <v-select
      v-model="data.type"
      :items="typeSelection"
      label="Tip"
      required
    ></v-select>
    <v-text-field
    v-model="data.price"
    :rules="[v => !isNaN(v) || 'Polje je obavezno i mora biti broj bez karaktera']"
    label="Cena"
    required
  ></v-text-field>
  <v-text-field
  v-model="data.size"
  :rules="[v => !isNaN(v) || 'Polje je obavezno i mora biti broj bez karaktera']"
  label="Velicina m2 (broj)"
  required
></v-text-field>
<v-textarea
   v-model="data.description"
   name="Opis"
   label="Opis"
></v-textarea>
<v-textarea
   v-model="data.descriptionEng"
   name="Opis"
   label="Opis engleski"
></v-textarea>
<v-textarea
   v-model="data.descriptionRus"
   name="Opis"
   label="Opis ruski"
></v-textarea>

  <v-text-field
      v-model="data.terraceNumber"
      :counter="30"
      label="Sprat"
      required
    ></v-text-field>
    <v-select
      v-model="data.heating"
      :items="heatingSelection"
      label="Klimatizacija"
      required
    ></v-select>

    <v-select
      v-model="data.yard"
      :items="yardSelection"
      label="Dvoriste"
      required
    ></v-select>

    <v-select
      v-model="data.parking"
      :items="parkingSelection"
      label="Parking"
      required
    ></v-select>

    <v-select
      v-model="data.basment"
      :items="basmentSelection"
      label="Podrum"
      required
    ></v-select>

    <v-text-field
    v-model="data.seaDistance"
    :counter="30"
    label="Udaljenost od mora"
    required
  ></v-text-field>

  <v-select
      v-model="data.status"
      :items="statusSelection"
      label="Status"
      required
    ></v-select>

    <v-select
      v-model="data.statusIzdavanje"
      :items="statusIzdavanjeSelection"
      label="Status za izdavanje"
      required
    ></v-select>

    <v-select
      v-model="data.statusProdaje"
      :items="statusProdajeSelection"
      label="Status za prodaju"
      required
    ></v-select>
    <v-select
      v-model="data.agent"
      :items="users"
      item-text='fullName'
      return-object
      filled
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Agent"
      required
    ></v-select>
    <v-file-input
    v-model="data.images"
    label="Izaberite fotografije"
    accept="image/*" 
    multiple
    prepend-icon="mdi-paperclip"
  >
    <template v-slot:selection="{ text }">
      <v-chip
        small
        label
        color="primary"
      >
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>
 
    <v-btn
      :disabled="!valid"
      color="primary"
      class="mr-4"
      @click="validate"
    >
    <v-icon>mdi-content-save</v-icon>
      Objavi nekretninu
    </v-btn>
    
    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
    <v-icon>mdi-delete</v-icon>
      Resetuj formu
    </v-btn>


  </v-form>
</v-card>

</center>
  </template>
  
  
 <script>
import apiPost from '../../../services/service'
import axios from 'axios'

  export default {
    data: () => ({
      data:{
         agent:'',
         ownerNumber:'',
         name:'',
         nameEng:'',
         nameRus:'',
         town: '',
         images:[],
         type:'',
         description:"",
         descriptionEng:"",
         descriptionRus:"",
         category:'',
         size:'',
         terraceNumber:"",
         heating:"",
         yard:"",
         parking:"",
         basment:"",
         seaDistance:"",
         status:"",
         statusIzdavanje:"",
         statusProdaje:"",
         bathroomsNum:"",
         roomsNum:''
      },
      valid: true,
      nameRules: [
        v => !!v || 'Ovo polje je obavezno',
        v => (v && v.length <= 80) || 'Ovo polje mora biti ispod 80 karaktera',
      ],
      
      townSelection: [
      "Andrijevica",
      "Bar",
      "Berane",
      "Bijelo Polje",
      "Budva",
      "Cetinje",
      "Danilovgrad",
      "Gusinje",
      "Herceg Novi",
      "Kolasin",
      "Kotor",
      "Mojkovac",
      "Niksic",
      "Petnjica",
      "Plav",
      "Pluzine",
      "Pljevlja",
      "Podgorica",
      "Rozaje",
      "Savnik",
      "Tivat",
      "Tuzi",
      "Ulcinj",
      "Zabljak"
      ],
      typeSelection: [
        "Bez soba",
        "Garsonjera",
        "Jednosoban",
        "Dvosoban",
        "Trosoban",
        "Cetvorosoban",
        "Petosoban",
      ],
      categorySelection: [
        "Stan",
        "Kuca",
        "Zemljiste",
        "Poslovni prostor",
        "Hotel",
        "Garaza",
        "Ugostiteljski objekat"
      ],
      heatingSelection: [
        "Da",
        "Ne",
      ],
      yardSelection: [
        "Da",
        "Ne",
      ],
      parkingSelection: [
        "Da",
        "Ne",
      ],
      basmentSelection: [
        "Da",
        "Ne",
      ],
      purposeSelection: [
        "Prodaja",
        "Izdavanje",
        "Stan na dan",
      ],
      statusSelection: [
        "Namjesteno",
        "Nenamjesteno",
      ],
      statusIzdavanjeSelection: [
        "Izdato",
        "Nije izdato",
      ],
      statusProdajeSelection: [
        "Prodato",
        "Nije prodato",
      ],
      users: [],
      
      checkbox: false,
    }),
    mounted() {
      axios.post("/users/getUsers")
            .then(async response => {

               this.users = await response.data.users 
               this.users.forEach(user => {
                user.fullName = user.firstName + ` ` + user.lastName
               })
            })
            .catch(error => {
                //Set error message 
                console.log(error.data)
            })
    },
    methods: {
      
      async validate () {
        const validate = this.$refs.form.validate()
        if(validate){
         this.data.agent = this.data.agent._id
         if(this.data.type == "Jednosoban") this.data.roomsNum = "1"
         if(this.data.type == "Dvosoban") this.data.roomsNum = "2"
         if(this.data.type == "Trosoban") this.data.roomsNum = "3"
         if(this.data.type == "Cetvorosoban") this.data.roomsNum = "4"
         if(this.data.type == "Petosoban") this.data.roomsNum = "5"
         await apiPost.createPost(this.data).then(async response => {
           console.log(response.data)
           this.$router.push('/admin/nekretnine')
         })
        }

      },
      reset () {
        this.$refs.form.reset()
      }
      
    },
  }
</script>


<style  scoped>
a{
  text-decoration: none;
}
.cardUp{
   padding:30px;
   width: 80%;
   display: flex;
   justify-content: left;
   align-items: center;
 }
 h4{
   font-weight: bold;
 }
.form{
   width: 80%;
   padding: 30px;
}
</style>
