<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
select{
  color: black
}
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');

</style>
