<template>
<center>
  
<div class="single">
    <div class="heading">
    <div style="text-align:left">
    <h2 class="name">{{data.name}}</h2>
    <span style="color:grey;">{{data.town}}</span><br>
    <span style="color:grey;">ID : {{data.refId}}</span>
    </div>
    <div class="btns">
    <div class="share" @click="$router.go(-1)"><v-icon class="mr-1" color="rgba(33,111,253,255)">mdi-arrow-left</v-icon> Nazad na pretragu</div>
<!--    <div class="share"><v-icon class="mr-1" color="rgba(33,111,253,255)">mdi-share-variant</v-icon>Podeli</div>-->
    </div>
    </div>
    <div class="price">
      € {{data.price}}
    </div> 
    <div class="galMsg">
    <div class="gallery">
  <div style="height:100%" id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li v-for="number in imagesNumber" :key="number"  data-target="#myCarousel" :data-slide-to="number"></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner">
      <div class="item active">
        <img :src="`/`+firstImage" class="imgGal" alt="Gallerija" style="width:100%;">
      </div>
      <div  v-for="image in data.images" :key="image" class="item">
        <img :src="`/`+image" alt="Gallerija" class="imageGal imgGal" style="width:100%;">
      </div>

    </div>

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
    </div>
    <div id="contact" class="message">
        <a :href="`tel:`+data.agent.phone"><h4 style="font-size:22px;" class="mb-4">Kontakt: {{data.agent.phone}}</h4></a>
        <h3 style="color:green;margin-bottom:20px"  v-if="succMsg">{{succMsg}}</h3>
        <h3 style="color:red;margin-bottom:20px" v-if="erMsg">{{erMsg}}</h3>
        <span style="color:rgb(65, 65, 65);text-align: left;" >Pošaljite poruku</span>
        <input type="text" v-model="contact.fname" placeholder="Vase ime..">
        <input type="text" v-model="contact.lname" placeholder="Vase prezime..">
        <input type="text" v-model="contact.email" placeholder="Vase email..">
        <textarea id="" v-model="contact.message"></textarea>
        <a href="#contact"> <div @click="sendEmail" class="searchBtn">Pošalji</div></a>
    </div>
    </div>
    
    <div class="infos">

      <div v-if="data.roomsNum" class="sobe">
        <span>Sobe</span>

        <div>
            <v-icon>fa-door-closed</v-icon>
            <p v-if="data.type == 'Jednosoban'">1</p>
            <p v-if="data.type == 'Dvosoban'">2</p>
            <p v-if="data.type == 'Trosoban'">3</p>
            <p v-if="data.type == 'Cetvorosoban'">4</p>
            <p v-if="data.type == 'Petosoban'">5</p>
        </div>
      </div>
      <div class="size">
        <span>Velicina</span>

        <div>
            <v-icon>fa-expand</v-icon>
            <p>{{data.size}}m2</p>
        </div>
      </div>
      <div class="category">
        <span>Kategorija</span>

        <div>
            <v-icon>fa-house</v-icon>
            <p>{{data.category}}</p>
        </div>
      </div>
      <div class="type">
        <span>Svrha</span>

        <div>
            <v-icon>fa-receipt</v-icon>
            <p>{{data.purpose}}</p>
        </div>
      </div>
    </div>
    <div class="about">
        <h3>Opis</h3>
        <p>{{data.description}}</p>
    </div>
    
    <div class="agent">
        <h5>Agent</h5>
        <div class="agentOpis">
            <img width="90px" height="90px" class="agentSlikaImg" :src="`/`+data.agent.image">
            <div>
            <h5 class="agentSlika mb-4 mt-3">{{data.agent.firstName}} {{data.agent.lastName}} </h5>
            <a :href="`mailto:`+data.agent.email"> <p class="agentSlika"><span style="color:rgba(33,111,253,255)">Email:</span> {{data.agent.email}}</p></a>
           <a :href="`tel:`+data.agent.phone"><p class="agentSlika"> <span style="color:rgba(33,111,253,255)">Broj telefona:</span>  {{data.agent.phone}}</p></a> 
        </div> 
        </div>
    </div>
    <div id="contact" class="messageMob">
        <a :href="`tel:`+data.agent.phone"><h4 style="font-size:22px;" class="mb-4">Kontkat: {{data.agent.phone}}</h4></a>
        <h3 style="color:green;margin-bottom:20px"  v-if="succMsg">{{succMsg}}</h3>
        <h3 style="color:red;margin-bottom:20px" v-if="erMsg">{{erMsg}}</h3>
        <span style="color:rgb(65, 65, 65);text-align: left;" >Pošaljite poruku</span>
        <input type="text" v-model="contact.fname" placeholder="Vase ime..">
        <input type="text" v-model="contact.lname" placeholder="Vase prezime..">
        <input type="text" v-model="contact.email" placeholder="Vase email..">
        <textarea id="" v-model="contact.message"></textarea>
        <a href="#contact"> <div @click="sendEmail" class="searchBtn">Pošalji</div></a>
    </div>
   
</div>

</center>
</template>

<script>
// import apiPost from '../../services/service'
import axios from 'axios'

export default {
    data() {
        return {
         formSend:false,
            succMsg:false,
            erMsg:false,
            firstImage:'',
            imagesNumber:[],
            items:[],
            contact:{
                fname:"",
                lname:"",
                email:"",
                message:"",
            },
            data:{
             },
        }
    },
    async mounted(){
      const id = this.$route.params.id
      const data  = { searchID: id }
      try {
        // Simple GET request using axios
        await await axios.post('/posts/get', data , {
        headers: {
            'Content-Type': 'application/json',
        }
       }).then(async response => {
          this.data = await response.data.posts[0]
     
            let price = await this.data.price;
            let newP =  price.toLocaleString('de-DE', { 
                style: 'currency', 
                currencyDisplay: 'code', 
                currency: 'EUR' 
             });
            this.data.price = newP.slice(0,-3)
          this.firstImage = this.data.images[0]
          this.data.images.shift()

          for (let index = 0; index < this.data.images.length; index++) {
            // const element = array[index];

            this.imagesNumber.push(index+1)
           
          }
          console.log(this.imagesNumber)
          this.contact.message = `Poštovani, želim da se informišem više o nekretnini - ${this.data.name} - ID ${this.data.refId}`
       })
      } catch (error) {
        console.log(error.message)
      }
    },
    methods: {
    
    async sendEmail(){
      this.erMsg = false
      this.succMsg = false
      if(this.contact.fname == '') return this.erMsg = "Sva polja su obavezna"
      if(this.contact.lname == '') return this.erMsg = "Sva polja su obavezna"
      if(this.contact.email == '') return this.erMsg = "Sva polja su obavezna"
      if(this.contact.message == '') return this.erMsg = "Sva polja su obavezna"
      await axios.post(`/posts/email`, this.contact)
      // this.formSend = true
      this.succMsg = "Uspešno ste poslali poruku"

    }
   }
}
</script>


<style scoped>
.price{
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: left;
}
.messageMob{
  display: none;
}
#myCarousel{
  height: 100% !important;
}
.imgGal{
  object-fit: cover;
  height: 500px !important;
}
.imageGal{
   object-fit: cover;
}
 h1, h2,h3,h4,h5, h6{
    font-weight: bold; -webkit-font-weight: bold;
 }
 a{
    text-decoration: none;
 }
*{
    box-sizing: border-box;
}
 .nekretnine{
    width: 20px;
    height: 20px;
    background: red;
 }
 .similar{
    width: 100%;
    height: 300px;
    margin-top: 40px;
    background: white;
 }
 .searchBtn{
  width: 100%;
  height: 55px;
  padding: 14px 6px;
  cursor: pointer;
  color: white;
  background: rgba(33,111,253,255);
  border-radius: 8px;
  text-align: center;
  padding-right: 20px;
  margin-top: 23px;
}
 .message input,  .messageMob input{
    width: 100%;
    margin-top: 12px;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid black;
 }
 .message textarea,  .messageMob textarea{
    width: 100%;
    margin-top: 12px;
    padding: 10px;
    height: 200px;
    border-radius: 7px;
    border: 1px solid black;
    resize: none;
 }
 .galMsg{
    flex-wrap: wrap;

    display: flex;
    justify-content: space-between;
 }
 .messageMob{
  margin-bottom: 70px;
  padding: 30px 20px;
  width: 100%;
 border: 2px solid #c9c9da;
 border-radius: 10px;

}
 .message{
  
    padding: 30px 20px;
    width: 300px;
   border: 2px solid #c9c9da;
   border-radius: 10px;

 }
 .agent{
    text-align: left;
    width: 670px;
   margin-top: 30px;
   margin-bottom: 50px;
   display: flex;
   flex-direction: column;
   justify-content: left;
 }
 .agentSlika{
    margin-left: 26px;
 }
 .agentSlikaImg{
   object-fit: cover;
    margin-left: 15px;

 }
@media only screen and (max-width: 442px) {
    .agentSlika{
    margin-left: 10px;
 }
 .agentSlikaImg{
    margin-left: 0px;

 }
}
 .agentOpis{
   background: #c9c9da;

   border-radius: 10px;
   flex-wrap: wrap;
   border: 2px solid #c9c9da;
    padding: 20px;
    margin-top: 20px;
    display: flex;
   justify-content: left;
   align-items: center;
 }
 .agent img{
    border-radius: 50%;
 }
 .about{
    margin-top: 40px;
    text-align: left;
    width: 670px;
 }
 .about p{
    word-wrap: break-word; 
    margin-top: 20px;
    color: rgb(121, 121, 121);
 }
 .infos {
    width: 670px;
   border: 2px solid #c9c9da;
   border-radius: 10px;
   margin-top: -70px;
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 20px;
 }
 .infos div{
    margin: 6px;
 }
 .infos div  span{
   color: rgb(142, 142, 142);
 }
 .infos div div p{
    margin: 6px;
 }
 .infos div div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 }
 .gallery {
   width: 670px;
   /* border: 2px solid #c9c9da; */
   border-radius: 10px;
   height: 500px;

 }
 .btns{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 20px;
 }
 .btns div{
    cursor: pointer;
    margin-right: 20px;
    border-radius: 4px;
    padding: 10px 13px;
    height: 48px;
    background: #e7e6f8ba;
    color: rgba(33,111,253,255);
    border:1px solid rgba(33,111,253,255) ;
 }
 .heading{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
 }
 .single{
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding-top: 180px;
    width: 1000px;
 }
@media only screen and (max-width: 765px) {
  .imgGal{
    object-fit: cover;
  height: 300px !important;
  }
  .gallery{
    height: 300px;
  }
  .infos{
    margin-top: -40px;
  }
  #myCarousel{
    object-fit: cover;

    height: 300px !important;
  }
  .btns{
    margin-top: 20px;
    margin-left: 0px;
  }
  .heading{
    flex-direction: column;
  }
}

@media only screen and (max-width: 1100px) {
  
    .single{
        width: 90%;
        padding-top: 110px;
    }
    .message{
        display: none;
    }
    .messageMob{
        display: block;
    }
    .heading,.agent ,.gallery,.about, .infos{
        width: 100%;
        margin-top: 20px;
    }

}


</style>