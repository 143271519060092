<template>
   <center>
    <v-card  class="mb-4 cardUp">
    <v-btn to="/admin/nekretnine" fab class="mr-3 mb-3" color="primary"><v-icon>mdi-arrow-left</v-icon></v-btn>
    <h4 class="mb-3 ml-12"  >Izmena nekretnine</h4>
    </v-card>
   
    <v-card class="form">
      <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
  <v-text-field
  v-model="data.ownerNumber"
  :counter="30"
  label="Broj vlasnika"
  required
></v-text-field>
    <v-text-field
      v-model="data.name"
      :counter="80"
      :rules="nameRules"
      label="Ime"
      placeholder="Na primer: Prodaja, 54m2 , Zabjelo..."
      required
    ></v-text-field>
    <v-text-field
      v-model="data.nameEng"
      :counter="80"
      label="Ime engleski"
      placeholder="Na primer: Selling, 54m2 , Zabjelo..."
      required
    ></v-text-field>
    <v-text-field
      v-model="data.nameRus"
      :counter="80"
      label="Ime ruski"
      placeholder="Na primer: Квартира в белый день..."
      required
    ></v-text-field>
   
    <v-select
      v-model="data.town"
      :items="townSelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Grad"
      required
    ></v-select>
    <v-select
      v-model="data.purpose"
      :items="purposeSelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Svrha"
      required
    ></v-select>
    <v-select
      v-model="data.category"
      :items="categorySelection"
      :rules="[v => !!v || 'Polje je obavezno']"
      label="Kategorija"
      required
    ></v-select>
    <v-text-field
      v-model="data.bathroomsNum"
      :counter="30"
      label="Broj kupatila"
      required
    ></v-text-field>
    <v-select
      v-model="data.type"
      :items="typeSelection"
      label="Tip"
      required
    ></v-select>
    <v-text-field
    v-model="data.price"
    :counter="30"
    :rules="nameRules"
    label="Cena"
    required
  ></v-text-field>
  <v-text-field
  v-model="data.size"
  :counter="30"
  :rules="nameRules"
  label="Velicina m2"
  required
></v-text-field>
<v-textarea
   v-model="data.description"
   name="Opis"
   label="Opis"
></v-textarea>
<v-textarea
   v-model="data.descriptionEng"
   name="Opis"
   label="Opis engleski"
></v-textarea>
<v-textarea
   v-model="data.descriptionRus"
   name="Opis"
   label="Opis ruski"
></v-textarea>

  <v-text-field
      v-model="data.terraceNumber"
      :counter="30"
      label="Sprat"
      required
    ></v-text-field>
    <v-select
      v-model="data.heating"
      :items="heatingSelection"
      label="Klimatizacija"
      required
    ></v-select>

    <v-select
      v-model="data.yard"
      :items="yardSelection"
      label="Dvoriste"
      required
    ></v-select>

    <v-select
      v-model="data.parking"
      :items="parkingSelection"
      label="Parking"
      required
    ></v-select>

    <v-select
      v-model="data.basment"
      :items="basmentSelection"
      label="Podrum"
      required
    ></v-select>

    <v-text-field
    v-model="data.seaDistance"
    :counter="30"
    label="Udaljenost od mora"
    required
  ></v-text-field>

  <v-select
      v-model="data.status"
      :items="statusSelection"
      label="Status"
      required
    ></v-select>

    <v-select
      v-model="data.statusIzdavanje"
      :items="statusIzdavanjeSelection"
      label="Status za izdavanje"
      required
    ></v-select>

    <v-select
      v-model="data.statusProdaje"
      :items="statusProdajeSelection"
      label="Status za prodaju"
      required
    ></v-select>
    <v-select
      v-model="data.agent"
      :items="users"
      item-text='fullName'
      return-object
      filled
      label="Agent"
      required
    ></v-select>
    <v-file-input
    v-model="data.images"
    label="Izaberite fotografije"
    accept="image/*" 
    multiple
    prepend-icon="mdi-paperclip"
  >
    <template v-slot:selection="{ text }">
      <v-chip
        small
        label
        color="primary"
      >
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>
 

    <v-btn
      :disabled="!valid"
      color="primary"
      class="mr-4"
      @click="validate"
    >
    <v-icon>mdi-content-save</v-icon>

      Izmeni nekretninu
      
    </v-btn>
    
    <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
    <v-icon>mdi-delete</v-icon>

      Obrisi sva polja
    </v-btn>


  </v-form>
</v-card>

</center>
  </template>
  
  
 <script>
import apiPost from '../../../services/service'
import axios from 'axios'

  export default {
    data: () => ({
      id:'',
      data:{
         agent:'',
         ownerNumber:'',
         name:'',
         nameRus:'',
         nameEng:'',
         town: '',
         images:[],
         type:'',
         category:'',
         size:'',
         terraceNumber:"",
         heating:"",
         yard:"",
         parking:"",
         description:"",
         descriptionEng:"",
         descriptionRus:"",
         basment:"",
         seaDistance:"",
         status:"",
         statusIzdavanje:"",
         statusProdaje:"",
         bathroomsNum:"",
         roomsNum:''
      },
      valid: true,
      nameRules: [
        v => !!v || 'Ovo polje je obavezno',
        v => (v && v.length <= 80) || 'Ovo polje mora biti ispod 30 karaktera',
      ],
      townSelection: [
      "Andrijevica",
      "Bar",
      "Berane",
      "Bijelo Polje",
      "Budva",
      "Cetinje",
      "Danilovgrad",
      "Gusinje",
      "Herceg Novi",
      "Kolasin",
      "Kotor",
      "Mojkovac",
      "Niksic",
      "Petnjica",
      "Plav",
      "Pluzine",
      "Pljevlja",
      "Podgorica",
      "Rozaje",
      "Savnik",
      "Tivat",
      "Tuzi",
      "Ulcinj",
      "Zabljak"
      ],
      typeSelection: [
        "Bez soba",
        "Garsonjera",
        "Jednosoban",
        "Dvosoban",
        "Trosoban",
        "Cetvorosoban",
        "Petosoban",
      ],
      categorySelection: [
        "Stan",
        "Kuca",
        "Zemljiste",
        "Poslovni prostor",
        "Hotel",
        "Garaza",
        "Ugostiteljski objekat"
      ],
      heatingSelection: [
        "Da",
        "Ne",
      ],
      yardSelection: [
        "Da",
        "Ne",
      ],
      parkingSelection: [
        "Da",
        "Ne",
      ],
      basmentSelection: [
        "Da",
        "Ne",
      ],
      purposeSelection: [
        "Prodaja",
        "Izdavanje",
        "Stan na dan",
      ],
      statusSelection: [
        "Namjesteno",
        "Nenamjesteno",
      ],
      statusIzdavanjeSelection: [
        "Izdato",
        "Nije izdato",
      ],
      statusProdajeSelection: [
        "Prodato",
        "Nije prodato",
      ],
      users:[],
    
    }),
    async mounted(){
      const id = this.$route.params.id
      this.id = id
      const data  = { searchID: id }
      try {
        // Simple GET request using axios
        await axios.post('/posts/get', data , {
        headers: {
            'Content-Type': 'application/json',
        }
       }).then(async response => {
          this.data = response.data.posts[0]
          this.data.old_images = this.data.images
          this.data.images = []
          this.data.price =  this.data.price.toString()
          this.data.size =  this.data.size.toString()
       })

       axios.post("/users/getUsers")
            .then(async response => {

               this.users = await response.data.users 
            })

       
       
      } catch (error) {
        console.log(error.message)
      }
    },
    methods: {
      async validate () {
        
        const validate = this.$refs.form.validate()

        if(validate){
         
          if(this.data.type == "Jednosoban")  this.data.roomsNum =  "1"
         if(this.data.type == "Dvosoban") this.data.roomsNum = "2"
         if(this.data.type == "Trosoban") this.data.roomsNum = "3"
         if(this.data.type == "Cetvorosoban") this.data.roomsNum = "4"
         if(this.data.type == "Petosoban") this.data.roomsNum = "5"
  


          var form_data = new FormData();

          for ( var key in this.data ) {
            await  form_data.append(key, this.data[key]);
          }

          form_data.set('agent', this.data.agent._id)
          form_data.delete('images');
          form_data.delete('old_images');
          for (const element of this.data.images) {
            form_data.append('images', element)
            
          }
          for (const element of this.data.old_images) {
            form_data.append('old_images', element)
            
          }
         


          
         

          // const Data = {data:form_data , id: this.id}

         await apiPost.EditPost({data:form_data, id:this.id}).then(async response => {
           console.log(response.data)
           this.$router.push('/admin/nekretnine')
         })
        }

      },
      reset () {
        this.$refs.form.reset()
      }
      
    },
  }
</script>


<style  scoped>
.form{
   width: 80%;
   padding: 30px;
}
.cardUp{
  padding:30px;
  width: 80%;
  display: flex;
  justify-content: left;
  align-items: center;
}
h4{
  font-weight: bold;
}
a{
  text-decoration: none;
}
</style>
