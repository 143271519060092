<template>
  <div>
    <center>
    <v-card class="card">
      <v-text-field class="pt-5 pl-5" v-model="IDField" label="Pretraži po ID-u"></v-text-field>
      <v-btn width="120px" color="primary" class="ml-4" @click="serachID">Pretrazi</v-btn>
     

        <v-card-title>Nekretnine</v-card-title>
        <div class="mt-5 mb-5" v-if="!progress && !size">Nema podataka</div>
        <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
        v-if="progress"
      ></v-progress-linear>
        <v-data-table
           v-if="!progress && size"
          :headers="headers"
          :items="desserts"
          disable-pagination
          :hide-default-footer="true"
        >
        <template v-slot:[`item.image`]="{ item }">
            <img  width="50px" height="50px"  class="ma-3 imgData" :src="`/`+item.images[0]" alt="slika">
          </template>
          <template v-slot:[`item.actions`]="{ item }">
           <v-btn :to='`/admin/izmeniNekretninu/`+item._id' small color="primary" fab><v-icon  color="white">mdi-pencil</v-icon></v-btn>
          <v-btn  @click="dialogDeleteFun(item._id)"  small color="red" fab> <v-icon color="white">mdi-delete</v-icon></v-btn>
       
          </template>
        
        </v-data-table>
        <v-dialog
        v-model="dialogDelete"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Brisanje nekretnine
          </v-card-title>
  
          <v-card-text class="pa-6">
             <strong> Da li ste sigurni da zelite da obrisete nekretninu.Vracanje nece biti moguce!</strong>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogDelete = false">
              <v-icon class="mr-1">mdi-close</v-icon>
              Odustani
            </v-btn>
            <v-btn
              color="red"
              dark
              @click="deleteItem()"
            >

            <v-icon color="white">mdi-delete</v-icon>
              OBRIŠI
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        </v-card>
        </center>
        <pagination
      :totalPages="lastPage"
      :perPage="9"
      :currentPage="page"
      @pagechanged="onPageChange"
    />
    </div>
</template>


<script>
import apiPost from '../../../services/service'
import axios from 'axios'
import Pagination from './PaginationCom.vue'

  export default {
    components: {
    Pagination
  },
   data () {
      return {
        lastPage: null,
        delData: {
          id:null,
        },
        dialogDelete:false,
        IDField:"",
        data:{},
        size:true,
        page:1,
        length:1,
        progress : false,
        headers: [
          {
            text: 'ID nekretnine',
            align: 'start',
            sortable: false,
            value: 'refId'
          },
          { text: 'Slika', value: 'image', sortable: false },
          { text: 'Tip', value: 'type', sortable: false },
          { text: 'Kvadratura', value: 'size' , sortable: false},
          { text: 'Status Izdavanje', value: 'statusIzdavanje', sortable: false },
          { text: 'Status Prodaje', value: 'statusProdaje', sortable: false },
          { text: "Akcije", value: "actions", sortable: false },
        ],
        desserts: [
        ],
      }
    },
    async mounted(){
      // this.$route.query.id = "Rade"
      // this.$router.push({ path: '/nekretnine', query: { plan: 'private' }})
      try {
        // Simple GET request using axios
        await apiPost.getPosts().then(async response => {
          this.desserts = await response.data.posts
          const page = await response.data.page
          this.page = page
          this.lastPage = await response.data.lastPage
          if(this.desserts.length == 0) this.size = false;
          
       })
       
      } catch (error) {
        console.log(error.message)
      }
    },
    methods:{
     async onPageChange(page) {
      this.page = page;

      //GETTING DATA INTO FORM DATA

      let data = { page : this.page }
      if(this.IDField !== '') data.refId = this.IDField
      //geting data
      await axios.post('/posts/get', data , {
        headers: {
            'Content-Type': 'application/json',
        }
      }).then(async response => {
        this.desserts = await response.data.posts
      })
     
      
      //how long action last
      setTimeout(() => {
              this.progress = false}, 1000)
    },
      dialogDeleteFun(id){
        this.dialogDelete = true
        this.delData.id = id
      }
      ,
      async serachID(){
        this.size = true
        this.progress = true
        this.desserts = []
        let data = { "refId" :  this.IDField }
        
        await axios.post('/posts/get', data , {
        headers: {
            'Content-Type': 'application/json',
        }
       })
        .then(async response => {
          this.desserts = await response.data.posts
          const page = await response.data.page
          this.page = page
          this.lastPage = await response.data.lastPage
          if(this.desserts.length < 1) this.size = false;
       })
       setTimeout(() => {
              this.progress = false}, 2000)  
      }
      ,
      async deleteItem(){
        
        await apiPost.deletePost(this.delData.id).then(async response => {
          console.log(response.data.message)
      })
      this.dialogDelete = false
      if(this.desserts.length < 1) this.size = false;
      
      const Data = this.data
      await apiPost.getPosts(Data).then(async response => {
          this.desserts = await response.data.posts
          const page = await response.data.page
          this.page = page

       })
      }
    }
  }
</script>

<style scoped>
a{
  text-decoration: none;
}
.pagination{
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
}
.paginationNum{
  border: 1px solid grey;
  padding: 10px;
  background-color: black;
  color:white;
  border-radius: 3px;
  cursor: pointer;
}
.imgData{
  border-radius: 10px;
  object-fit: cover;
}
.active{
  background-color: yellow;
  color: black;
}
.card{
  width: 95%;
}
a{
  text-decoration: none;
}
</style>