import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/Home.vue'
import HomeEng from '../components/homeEng/Home.vue'
import HomeRus from '../components/homeRus/Home.vue'
import AdminPanel from '../components/Admin/Layout/AdminPanel'
import LoginView from '../components/LoginView.vue'
import NekretnineView from '../components/Admin/Pages/NekretnineView'
import NekretnineAdd from '../components/Admin/Pages/AddNekretnine'
import IzmenaNekretnine from '../components/Admin/Pages/EditNekretnine'
import AddUser from '../components/Admin/Pages/AddUser'
import MainComponent from '../components/home/MainComponent.vue'
import MainComponentEng from '../components/homeEng/MainComponent.vue'
import MainComponentRus from '../components/homeRus/MainComponent.vue'
import SingleComponent from '../components/home/SingleEstate.vue'
import SingleComponentEng from '../components/homeEng/SingleEstate.vue'
import SingleComponentRus from '../components/homeRus/SingleEstate.vue'
import HomePage from '../components/home/HomePage.vue'
import HomePageEng from '../components/homeEng/HomePage.vue'
import HomePageRus from '../components/homeRus/HomePage.vue'
// import AboutPage from '../components/home/AboutPage.vue'
import ContactPage from '../components/home/ContactPage.vue'
import ConsultingPage from '../components/home/ConsultingPage.vue'
import ContactPageEng from '../components/homeEng/ContactPage.vue'
import ConsultingPageEng from '../components/homeEng/ConsultingPageEng.vue'
import ContactPageRus from '../components/homeRus/ContactPage.vue'
import ConsultingPageRus from '../components/homeRus/ConsultingPageRus.vue'
import NotFound from '../components/NotFound.vue'
Vue.use(VueRouter)

const routes = [

  //Paths for website

  //eng version
  {
    path: "/en",
    component: HomeEng,
    children: [
      {
        path: '/en/realestate',
        name: 'Main',
        meta: { title: 'Real Estate' },
        component: MainComponentEng
      },
      {
        path: '/en/realestate/:id',
        name: 'SingleNekretnine',
        meta: { title: 'Real Estate' },
        component: SingleComponentEng
      },
      {
        path: '/en',
        name: 'HomePage',
        meta: { title: 'Home' },
        component: HomePageEng
      },
      // {
      //   path: '/onama',
      //   name: 'About',
      //   meta: { title: 'O nama' },
      //   component: AboutPage
      // },
      {
        path: '/en/contact',
        name: 'Contact',
        meta: { title: 'Contact' },
        component: ContactPageEng
      },
      {
        path: '/en/consulting',
        name: 'HomePage',
        meta: { title: 'Consulting' },
        component: ConsultingPageEng
      },
    ]
  },


  //russian version

  {
    path: "/rus",
    component: HomeRus,
    children: [
      {
        path: '/rus/nekretnine',
        name: 'Main',
        meta: { title: 'Nekretnine' },
        component: MainComponentRus
      },
      {
        path: '/rus/nekretnine/:id',
        name: 'SingleNekretnine',
        meta: { title: 'Nekretnina' },
        component: SingleComponentRus
      },
      {
        path: '/rus',
        name: 'HomePage',
        meta: { title: 'Početna' },
        component: HomePageRus
      },
      // {
      //   path: '/onama',
      //   name: 'About',
      //   meta: { title: 'O nama' },
      //   component: AboutPage
      // },
      {
        path: '/rus/kontakt',
        name: 'Contact',
        meta: { title: 'Kontakt' },
        component: ContactPageRus
      },
      {
        path: '/rus/consulting',
        name: 'HomePage',
        meta: { title: 'Consulting' },
        component: ConsultingPageRus
      },
    ]
  },

  //mne
  {
    path: "/",
    component: Home,
    children: [
      {
        path: '/nekretnine',
        name: 'Main',
        meta: { title: 'Nekretnine' },
        component: MainComponent
      },
      {
        path: '/nekretnine/:id',
        name: 'SingleNekretnine',
        meta: { title: 'Nekretnina' },
        component: SingleComponent
      },
      {
        path: '/',
        name: 'HomePage',
        meta: { title: 'Početna' },
        component: HomePage
      },
      // {
      //   path: '/onama',
      //   name: 'About',
      //   meta: { title: 'O nama' },
      //   component: AboutPage
      // },
      {
        path: '/kontakt',
        name: 'Contact',
        meta: { title: 'Kontakt' },
        component: ContactPage
      },
      {
        path: '/consulting',
        name: 'HomePage',
        meta: { title: 'Consulting' },
        component: ConsultingPage
      },
    ]
  },

  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: { title: '404' }

  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { title: 'Admin' }
  },

  //Paths for admin panel
  {
  path: "/admin",
  component: AdminPanel,
  meta: { title: 'Admin' },
  children: [
    {
      path: '/admin/nekretnine',
      name: 'AdminNekretnine',
      meta: { title: 'Admin' },
      component: NekretnineView

    },
    {
      path: '/admin/dodajNekretninu',
      name: 'AdminAddNekretnine',
      meta: { title: 'Nova nekretnina' },
      component: NekretnineAdd

    },
    {
      path: '/admin/izmeniNekretninu/:id',
      name: 'AdminEditNekretnine',
      meta: { title: 'Izmena nekretnine' },
      component: IzmenaNekretnine

    },
    {
      path: '/admin/korisnici',
      name: 'AdminKorisnici',
      meta: { title: 'Admin korisnici' },
      component: AddUser

    },
  ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "activeMob",
  linkExactActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}
export default router

//SET TITLE TO LOAD AS INSERTED
const DEFAULT_TITLE = 'Web Page';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});


//Check if token exist to protect routes// TODO:Check secret key
router.beforeEach((to, from, next) => {
  
  if (localStorage.getItem('token')) {
    var expired = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
    var time = (Date.now() < expired.exp * 1000)
  }
    if(time && to.name==="Login"){
      next({name: 'AdminNekretnine'})
    }
    // If logged in, or going to the Login page.
    if (time ||  to.name === 'Not Found' || to.name === "Login"  || to.name === "Main" || to.name === "MainEng" || to.name === "MainRus" || to.name === "HomePage" || to.name === "About" || to.name === "Contact" || to.name === "SingleNekretnine") {
      // Continue to page.
      next()
    } else {
      // Not logged in, redirect to login.
      next({name: 'Login'})
    }
});
