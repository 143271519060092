<template>
    <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                   
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Prijava </v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                            <v-alert
                            v-if="errMsg"
                       dense
                       outlined
                       type="error"
                     >
                       {{this.errMsg}}
                     </v-alert>
                           <v-text-field
                              prepend-icon="person"
                              name="email"
                              label="Email"
                              v-model="email"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="lock"
                              name="password"
                              label="Lozinka"
                              type="password"
                              v-model="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="login">Prijavi se</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>
</template>

<script>
import axios from "axios"
export default {
    data: () => ({
        email:'',
        password:'',
        errMsg:null
    }),
    methods: {
        login() {
             let data = {
                email:this.email,
                password:this.password
             }
             axios.post("/users/login", data )
            .then(async response => {
                await window.localStorage.setItem("token", response.data.token);
                this.$router.push({ path: "/admin/nekretnine" });    
            })
            .catch(error => {
                //Set error message 
                this.errMsg = error.response.data
            })
        },
    }
};
</script>

<style scoped>
.container.fill-height > .layout {
    height: 100vh;
}
</style>