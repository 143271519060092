<template>
    <div style="width:100%">
    <div class="spac"></div>
<div class="entering">
        <div class="text">
            <h1 style="">Kupi , iznajmi ili prodaj nekretninu brzo i jednostavno!</h1>
            <v-card>
    <v-tabs
      color="rgba(33,111,253,255)"
      left
    >
      <v-tab @click="data.purpose = `Prodaja`">Prodaja</v-tab>
      <v-tab @click="data.purpose = `Izdavanje`"  >Izdavanje</v-tab>
      <v-tab @click="data.purpose = `Stan na dan`">Stan na dan</v-tab>
    </v-tabs>
    
  </v-card>  
         <div class="search">
         <div  class="searchField"  style="  border-bottom-left-radius: 0px;
  border-top-bottom-radius: 0px;
">
         
          <div class="select" >
              <label for="town">Grad:</label>
            <select v-model="data.town" name="town">
              <option default selected value="">Izaberite</option>
              <option value="Andrijevica">Andrijevica</option>
              <option value="Bar">Bar</option>
              <option value="Berane">Berane</option>
              <option value="Bijelo Polje">Bijelo Polje</option>
              <option value="Budva">Budva</option>
              <option value="Cetinje">Cetinje</option>
              <option value="Danilovgrad">Danilovgrad</option>
              <option value="Gusinje">Gusinje</option>
              <option value="Herceg Novi">Herceg Novi</option>
              <option value="Kolasin">Kolašin</option>
              <option value="Kotor">Kotor</option>
              <option value="Mojkovac">Mojkovac</option>
              <option value="Niksic">Nikšić</option>
              <option value="Petnjica">Petnjica</option>
              <option value="Plav">Plav</option>
              <option value="Pluzine">Plužine</option>
              <option value="Pljevlja">Pljevlja</option>
              <option value="Podgorica">Podgorica</option>
              <option value="Rozaje">Rožaje</option>
              <option value="Savnik">Šavnik</option>
              <option value="Tivat">Tivat</option>
              <option value="Tuzi">Tuzi</option>
              <option value="Ulcinj">Ulcinj</option>
              <option value="Zabljak">Žabljak</option>
            </select>
          </div>
          <div class="select">
              <label for="type">Vrsta:</label>
            <select v-model="data.category" name="type">
              <option default selected value="">Izaberite</option>

              <option value="Stan">Stan</option>
              <option value="Kuca">Kuća</option>
              <option value="Zemljiste">Zemljište</option>
              <option value="Poslovni prostor">Poslovni prostor</option>
              <option value="Hotel">Hotel</option>
              <option value="Garaza">Garaža</option>
              <option value="Ugostiteljski objekat">Ugostiteljski objekat</option>

            </select>
          </div>
             <div class="select">
              <label for="structure">Struktura:</label>
            <select v-model="data.type" name="structure">
              <option default selected value="">Izaberite</option>

              <option value="Garsonjera">Garsonjera</option>
              <option value="Jednosoban">Jednosoban</option>
              <option value="Dvosoban">Dvosoban</option>
              <option value="Trosoban">Trosoban</option>
              <option value="Cetvorosoban">Četvorosoban</option>
              <option value="Petosoban">Petosoban</option>
            </select>
          </div>
    

        </div>
 <div class="searchField " style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
       
            <div class="select">
              <label for="price">Cijena:</label>
             <section class="priceDrop" >

              <input v-model="data.priceMin" style="margin-right:10px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Od:">
              <input v-model="data.priceMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Do:">
             </section>

            
          </div>
         
        </div>
        <div class="searchField " style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
       
          <div class="select" >
             <label for="purpose">Kvadratura:</label>
              <section class="priceDrop" >

              <input v-model="data.sizeMin" style="margin-right:10px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Od:">
              <input v-model="data.sizeMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Do:">
              </section>
          </div>
        </div>
           <div class="" style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
          <div><div @click="search()"  class="searchBtn">Pretraži</div></div>

           </div>
        
    </div>
        </div>
        <div class="textMobile">
          <h2>Kupi , iznajmi ili prodaj nekretninu brzo i jednostavno!</h2>
        <v-app-bar class="pretrazi" elevation="1"  color="white">Pretraži nekretnine</v-app-bar>

               <div class="search" style="  border-top-left-radius: 0px;
         
         border-top-right-radius: 0px;
       ">
                
         <div  class="searchFieldMob"  style="  border-bottom-left-radius: 0px;
         
  border-bottom-right-radius: 0px;
">
         <div class="select" >
              <label for="purpose">Svrha:</label>
            <select v-model="data.purpose" name="town">
              <option default selected value="">Izaberite</option>
              <option value="Prodaja">Prodaja</option>
              <option value="Izdavanje">Izdavanje</option>
              <option value="Stan na dan">Stan na dan</option>
            </select>
          </div>
          <div class="select" >
              <label for="town">Grad:</label>
            <select v-model="data.town" name="town">
              <option default selected value="">Izaberite</option>
  
              <option value="Andrijevica">Andrijevica</option>
              <option value="Bar">Bar</option>
              <option value="Berane">Berane</option>
              <option value="Bijelo Polje">Bijelo Polje</option>
              <option value="Budva">Budva</option>
              <option value="Cetinje">Cetinje</option>
              <option value="Danilovgrad">Danilovgrad</option>
              <option value="Gusinje">Gusinje</option>
              <option value="Herceg Novi">Herceg Novi</option>
              <option value="Kolasin">Kolašin</option>
              <option value="Kotor">Kotor</option>
              <option value="Mojkovac">Mojkovac</option>
              <option value="Niksic">Nikšić</option>
              <option value="Petnjica">Petnjica</option>
              <option value="Plav">Plav</option>
              <option value="Pluzine">Plužine</option>
              <option value="Pljevlja">Pljevlja</option>
              <option value="Podgorica">Podgorica</option>
              <option value="Rozaje">Rožaje</option>
              <option value="Savnik">Šavnik</option>
              <option value="Tivat">Tivat</option>
              <option value="Tuzi">Tuzi</option>
              <option value="Ulcinj">Ulcinj</option>
              <option value="Zabljak">Žabljak</option>
            </select>
          </div>
          <div class="select">
              <label for="type">Vrsta:</label>
            <select v-model="data.category" name="type">
              <option default selected value="">Izaberite</option>

              <option value="Stan">Stan</option>
              <option value="Kuca">Kuća</option>
              <option value="Zemljiste">Zemljište</option>
              <option value="Poslovni prostor">Poslovni prostor</option>
              <option value="Hotel">Hotel</option>
              <option value="Garaza">Garaža</option>
              <option value="Ugostiteljski objekat">Ugostiteljski objekat</option>
            </select>
          </div>
             <div class="select">
              <label for="structure">Struktura:</label>
            <select v-model="data.type" name="structure">
              <option default selected value="">Izaberite</option>

              <option value="Garsonjera">Garsonjera</option>
              <option value="Jednosoban">Jednosoban</option>
              <option value="Dvosoban">Dvosoban</option>
              <option value="Trosoban">Trosoban</option>
              <option value="Cetvorosoban">Četvorosoban</option>
              <option value="Petosoban">Petosoban</option>
            </select>
          </div>
    

        </div>
 <div class="searchFieldMob " style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
       
            <div class="select">
              <label for="price">Cijena:</label>
             <section class="priceDrop" >

              <input v-model="data.priceMin" style="margin-right:10px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Od:">
              <input v-model="data.priceMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Do:">
             </section>

            
          </div>
         
        </div>
        <div class="searchFieldMob " style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
       
          <div class="select" >
             <label for="purpose">Kvadratura:</label>
              <section class="priceDrop" >

              <input v-model="data.sizeMin" style="margin-right:10px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Od:">
              <input v-model="data.sizeMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="Do:">
              </section>
          </div>
        </div>
           <div class="" style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
          <div><div @click="search()"  class="searchBtn">Pretraži</div></div>

           </div>
        
    </div>   
        </div>
        <div class="image">
            <!-- <img src="homes.png" alt="homes"> -->
        </div>
     </div>

     <div class="newest">
      <div class="najnovije">
        <h2>Najnovije nekretnine</h2>
      </div>
      <main>
    
    <div class="cards">
      <div v-for="item in items" :key="item._id"  class="card">

      <router-link :to="`/nekretnine/`+item._id">

       <div class="cardImage">
        <img :src="'/'+item.images[0]" alt="nekretnina">
       </div>
       <div class="cardContent">
        <div class="hedCard">
          <div class="rent">
        <span class="price">€ {{item.price }}</span> <br>
        </div><br>
        <div v-if="item.purpose == 'Prodaja'" class="category">
           Prodaja
        </div>
        <div v-if="item.purpose == 'Izdavanje'" style="background-color:black" class="category">
           Izdavanje
        </div>
        <div v-if="item.purpose == 'Stan na dan'" style="background-color:red" class="category">
          Stan na dan
        </div>
        </div>
       <h4 style="  font-weight: bold; -webkit-font-weight: bold; margin-top:7px">{{item.name}}</h4>
        <p style="color:grey" >{{item.town}}</p>

        <hr>
        <div class="cardFooter">
          <div v-if="item.roomsNum"  class="bed">
            <img src="/bedFin.png" alt=""> {{ item.roomsNum + "  soba"}}
          </div>
          <div class="bed" v-if="item.bathroomsNum && Number(item.bathroomsNum) > 0">
            <img src="/bathroom.png" alt=""> {{ item.bathroomsNum + "   kupatila"}}
          </div>
          <div class="bed">
            <img src="/maximize.png" alt=""> {{ item.size + " m2" }}
          </div>
        </div>
      </div>
      </router-link>
      </div>
     
    
    </div>
  </main>
     </div>
     <center>
      <div class="aboutSection">

     <div class="aboutus">
      
      <div class="textAbout">
        <h2>O nama</h2><br>
       <p style="text-align:left">
        Trust real estate &amp; consulting d.o.o. Podgorica je preduzeće osnovano 2022. godine,
prepoznajući aktuelne i buduće trendove, ekspanziju i rastuće potrebe na cjelokupnom
tržištu nekretnina. Pratimo našu viziju da svojim klijentima i partnerima, koje stičemo
zahvaljujući posvećenosti poslu i otvorenom i jedinstvenom pristupu klijentu, pružimo
kompletnu uslugu prilikom potrage za savršenim prostorom za život ili rad i samim tim
posredovanje u kupoprodaji ili rentiranju nekretnina podignemo na viši nivo.
Trust real estate &amp; consulting Vam dakle pruža usluge posredovanja u prodaji, kupovini ili
rentiranju svih vrsta nepokretnosti. Pored toga, nakon kupovine nepokretnosti, nudimo Vam
pomoć u regulisanju potrebne dokumentacije u državnim i institucijama lokalne
samouprave, kao i usluge kreditnog savjetovanja od strane tima sa preko 10 godina iskustva
u bankarskom sektoru.
Pored nekretnina, bavimo se i consultingom u oblasti javnih nabavki, gdje Vam savjete i
usluge pruža tim sa decenijskim iskustvom u ovoj oblasti.
       </p> 

      </div>
      <div class="image">
        <img  src="https://brandclever.in/wp-content/uploads/2022/04/about-us-472x332-1.jpg" alt="">
      </div>
     </div>
     </div>
     </center>
    </div>
</template>


<script>
import apiPost from '../../services/service'

export default {
    data () { 
      return{
      items: [],
      data:{
        category:"",
        town:"",
        purpose:"Prodaja",
        status:"",
        type:"",
        priceMin:"",
        priceMax:"",
        sizeMin:"",
        sizeMax:"",
        sort:"Najnovije",
        roomsNum:'',
        bathroomsNum:''
      },
      }
    },
      async mounted () {

      try {     
        // Simple GET request using axios
        await apiPost.getPostsHome().then(async response => {

          this.items = await response.data.posts
          this.items.forEach(item => {
            let price = item.price;
            let newP =  price.toLocaleString('de-DE', { 
                style: 'currency', 
                currencyDisplay: 'code', 
                currency: 'EUR' 
             });
            item.price = newP.slice(0,-3)
          })
       })
       
      } catch (error) {
        console.log(error.message)
      }
    },
    methods: {
      search(){
        this.$router.push({ path: '/nekretnine', query: this.data })
      }
    },
}

</script>

<style scoped>
.najnovije h2, .aboutNaslov{
  padding-top:70px;
  font-weight: bold; -webkit-font-weight: bold;
  font-size: 35px;
}

.pretrazi{
  margin-bottom: 0px;
  border-bottom: 1px solid black;
  border-top-left-radius: 10px;
}
a{
  text-decoration: none;
}
/* ABOUT US */
.textAbout h2{
  font-weight: bold; -webkit-font-weight: bold;
  font-size: 40px;
}
.aboutSection{
  padding-top:36px;
  background: white;

}
select{
  cursor: pointer;
}
.aboutus{
  font-weight: bold; -webkit-font-weight: bold;
  width: 1160px;
  margin-top: 120px;
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

.textAbout{
  width: 500px;
} 
.image img{
  width: 440px;
}
  .textAbout p{
    color: rgb(114, 114, 114);
  }

 /* NAJNOVIJE */
 main{
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto,60px)) 1fr;
  grid-gap: 40px;
  padding: 60px 0px;
}
.cards{
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns:  repeat(12, minmax(auto,60px));
  grid-gap: 40px;
  
}
.card{
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.card:hover{
  transform: translateY(-7px);
}
.cardImage{
  width: 100%;
  padding-top:55% ;
  overflow: hidden;
  position: relative;
}
.cardImage img{
  border-top-left-radius: 12px;
  border-top-right-radius: 6px;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cardContent{
  padding: 20px 20px 20px 20px;
}
.price{
  color: rgba(33,111,253,255);
  font-weight: bold; -webkit-font-weight: bold;
  font-size: 22px;
}
.location{
  font-weight: bold; -webkit-font-weight: bold;
  color: #919191;
}
.cardFooter{
  color: #919191;
  font-weight: bold; -webkit-font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.cardFooter img{
  margin-right: 5px;

}
.hedCard{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.category{
  background:rgba(33,111,253,255) ;
  pad: 5px;
  border-radius: 4px;

  text-align: center;
  width: 100px;
  height: 30px;
  padding-top: 2px;
  color: white;
}
.rent{
  color: grey;
}
.image img {
    margin-top: 80px;
  }
@media only screen and (max-width:1227px){
  
  
  .aboutus{
    width: 92%;
  }
  
}
@media only screen and (max-width:1100px){
  .image img {
    margin-top: 0px;
  }
.aboutSection{
  padding-top: 10px;
}
  .card{
    grid-column-end: span 6;
  }
   .searchField{
    display: none;
  }
  .aboutus{
  
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }.image{
    margin-top: 50px;
  }
  .textAbout{
    margin-top: 60px;
    width: 100%;
    justify-content: center;
  }
  .textAbout p{
    width: 60%;
  }

}
@media only screen and (max-width:700px){
  main{
    grid-gap: 20px;
  }
  .card{
    grid-column-end: span 12;
  }
  .aboutus{
    width: 90%;
  }
   .searchField{
    display: none;
  }
  .textAbout p{
    width: 100%;
  }
  .image img{
    width: 100%;
  }

}

.najnovije{
  display: flex;
  justify-content: center;
  width: 100%;
}

 








/* ENTERING IMAGE TEXT SEARCH etc/... */
 .search{
    background: white;
    padding: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

 }
 .select{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
 }
 .priceDrop{
    width: 100%;
 }
 label{
    padding-left: 5px;
    color: grey;
 }
 select{
    padding: 10px;
 }
 input{
  width: 48%;
 }
 .searchField{
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    flex-direction: row;
 }
 .entering{
  color: white;
  background:rgba(0, 0, 0, 0.659) url('https://www.trustnekretnine.me/bild.avif');
  background-blend-mode: darken;
  background-position: center;
  padding-top: 110px;
  padding-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  -webkit-background:rgba(0, 0, 0, 0.659) url('https://www.trustnekretnine.me/bild.avif');
  -webkit-background-blend-mode: darken;
  -webkit-background-position: center;
  -webkit-padding-top: 110px;
  -webkit-padding-bottom: 100px;
  -webkit-background-size: cover;
  -webkit-background-repeat: no-repeat;
  -webkit-width: 100%;
  /* background: linear-gradient(180deg, #E0DEF7 0%, rgba(224, 222, 247, 0) 100%); */
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .text{
  margin-left: 10%;
 }
 .entering .textMobile{
      display: none;
    }
  
  @media only screen and (max-width: 790px) {
    .entering .text{
      display: none;
    }
    .entering .textMobile{
      display: block;
      width: 100%;
      margin: 40px;
    }
    .search{
      border-radius: 10px;
    }
    .textMobile h2{
      font-weight: bold; -webkit-font-weight: bold;
      font-size: 50px;
      margin-bottom: 50px;
    }.entering{
      
    }
  }
  input{
  color: black;
  }
  @media only screen and (max-width:500px){
  .textMobile h2{
    font-size: 40px;
  }
  input{
    width: 46%;
  }
  .entering .textMobile{
    margin: 20px ;
  }
  .najnovije h2{
    font-size: 27px;
  }
  .image img{
    width: 100%;
  }
  main{
    grid-template-columns:10px repeat(6, 1fr) 10px;
    grid-gap: 10px;
  }

   .searchField{
    display: none;
  }
  .cards{
    grid-column: 2 / span 6;
    grid-template-columns:repeat(6, 1fr);
    grid-gap: 20px;

  }
  .card{
    grid-column-end: span 6;
  }
}
 .text h1{
    font-weight: bold; -webkit-font-weight: bold;
    width:570px;
    letter-spacing: 1px;
    font-size:50px;
    margin-bottom: 52px;
    margin-top: 72px;

 }
 .searchBtn{
  width: 100%;
  height: 55px;
  padding: 14px 6px;
  cursor: pointer;
  color: white;
  background: rgba(33,111,253,255);
  border-radius: 8px;
  text-align: center;
  padding-right: 20px;
  margin-top: 23px;
}
 .image{
    
    float: right;
 }
</style>