<template>
    <v-footer
   padless
   class="footer"
  >
    
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      
    }),
  }
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
}
</style>