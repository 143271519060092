<template>
<div>
  <div class="contact">

    <div  class="forma" >
    <h2 style="color:green;margin-bottom:20px"  v-if="formSend">{{this.succ}}</h2>
    <h2 style="color:red;margin-bottom:20px" v-if="formField">Sva polja su obavezna!</h2>

    <h1>Kontaktiraj nas!</h1>
      <form action="">
      <div class="redovi">
        <div>
      <label for="name">Ime</label><br>
      <input v-model="name"  type="text" name="name" required>
      </div>
      <div>
      <label class="sh" for="lname">Prezime</label><br>
      <input class="last sh" v-model="lname"  type="text" name="lname" required><br>
      </div>
      </div>
      <label for="email">Email</label><br>
      <input type="email" v-model="email"  required><br>
      <label for="Message">Poruka</label><br>
      <textarea name="Message" v-model="message"   cols="30" rows="10" required></textarea>
      
      <button class="subm"  type="submit" @click.prevent="sendEmail" >POŠALJI  PORUKU
          <v-progress-circular
          v-if="loading"
      indeterminate
      color="white"
    ></v-progress-circular></button>
      </form>
    </div>

    <div class="imageInfo">
      
        <div>
                <h2 class="info2">Informacije</h2>
                <p><a href="tel:+38267009097"><strong class="str" > <v-icon color="rgba(33,111,253,255)">mdi-phone</v-icon> Broj telefona: </strong>067/009-097</a></p>
                <p><a href="tel:+38269570766"><strong class="str" > <v-icon color="rgba(33,111,253,255)">mdi-phone</v-icon> Broj telefona: </strong>069/570-766</a></p>
                <p><a href="mailto:trustconsulting081@gmail.com"><strong class="str"> <v-icon color="rgba(33,111,253,255)">mdi-email</v-icon> Mail: </strong>trustconsulting081@gmail.com</a></p>
                <p><a href="#"><strong class="str"> <v-icon color="rgba(33,111,253,255)">mdi-map-marker</v-icon> Adresa: </strong>Trg nezavisnosti 31</a></p>
                <!-- <h2 style="margin-bottom:20px; margin-top:30px; font-size: 23px" class="info2">Društvene mreže</h2>
                <v-icon color="rgba(33,111,253,255)">mdi-instagram</v-icon> -->
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d736.0915850705679!2d19.262802971529858!3d42.44122094101995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134deb48fba217e1%3A0xc6f5f9d34f27fa9c!2z0KLRgNCzINC90LXQt9Cw0LLQuNGB0L3QvtGB0YLQuA!5e0!3m2!1ssr!2s!4v1668588267105!5m2!1ssr!2s" class="map" height="310" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>              
              </div>
              
    </div>

  </div>
 </div>
</template>
<script>
import axios from 'axios'

export default {
    data () {
        return {
            name: '',
            lname: '',
            email:'',
            message:'',
            formSend: false,
            loading: false,
            succ:null,
            formField: false,
 
      }
     
},
    methods: {
    
      async sendEmail(){
      this.loading = true;
      let formData = {
        'fname':this.name,
        "lname": this.lname,
        'email': this.email,
        'message': this.message
      }

      try {
        // Simple GET request using axios
        if(this.name == '' || this.lname == '' || this.email == '' || this.message == '') {
             this.formField = true; this.loading = false;
             window.scrollTo(0,0);

        }else{
          console.log(formData)
          await axios.post(`/posts/email`, formData).then(async response => {

          await response.data
          this.succ = response.data.message
          console.log(this.succ)
          
          this.formField = false
          this.loading = false
          this.formSend = true
          window.scrollTo(0,0);
          this.name = ''
           this.lname = ''
           this.email = ''
           this.message = ''

       })
        }
        
       
      } catch (error) {
        console.log(error.message)
      }

      }
        
    },
}
</script>


<style scoped>
.info2{
    font-weight: bold; -webkit-font-weight: bold;
    margin-bottom: 45px;
}
.str{
    color: rgba(33,111,253,255);
    font-size: 18px;
}
a{
    text-decoration: none;
    list-style: none;
 }
.last{
    width: 100%;
}
.forma{
    margin: 0px 22px;
}
.btn{
    width: 100%;
    height: 100%;
}
.subm{
    border: 2px solid rgb(0, 0, 0);
    padding: 14px 1px;
    width: 180px;
    font-weight: bold; -webkit-font-weight: bold;
    font-size: 15px;
    border-radius: 6px;
    background-color: black;
    color: white;
    letter-spacing: 1px;
    transition: 0.2s ease-in-out;

}
/* .subm:hover{
    transition: 0.2s ease-in-out;
    background-color: white;
    color: black;
    border: 2px solid black;
} */
.redovi{
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
}
.contact{
    padding-top:140px ;
    margin-bottom: 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.redovi div{
    width: 49%;
}
input, textarea{
    width: 100%;
    border: 2px solid rgb(192, 192, 192);
    margin-top: 2px;
    margin-bottom: 3px;
    padding: 10px;
    border-radius: 5px;
}
textarea {
   resize: none;
}

.sh{
    margin-left: 9px;
}
.forma h1{
    font-weight: bolder;
    margin-bottom: 24px;
    color: black;
}
label{
    font-size: 18px;
    margin-bottom: 0px;
}
.imageInfo{
    text-align: left;
    margin-top: 12px;
    margin-left: 70px;
}
.map{
  width: 600px;
}
@media only screen and (max-width: 1228px) {
  .info2{
        margin-top: 60px;
    }

}
@media only screen and (max-width: 812px) {
  .map{
  width:100%;
}
  .info2{
        margin-top: 60px;
    }
}
@media only screen and (max-width: 850px) {
  
  .imageInfo{
    margin-left: 0px;
  }
  .slika{
    width: 310px;
    height: 310px;
  }
}
@media only screen and (max-width: 600px) {
    
  .redovi div{
    width: 100%;
   }
   .sh{
    margin-left: 0;
   }
}
</style>