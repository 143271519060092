<template>
    <v-app id="inspire">
        
      <v-main>
         <v-container>
            <v-layout align-center justify-center wrap>
               <v-flex wrap>
                   
                  <v-card class="elevation-12 card1">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title>Dodaj korisnika </v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                            <v-alert
                            v-if="errMsg"
                       dense
                       outlined
                       type="error"
                     >
                       {{this.errMsg}}
                     </v-alert>
                     <div
                        class="base-image-input"
                        :style="{ 'background-image': `url(${imageData})` }"
                        @click="chooseImage"
                    >
                        <span
                        v-if="!imageData"
                        class="placeholder"
                        >
                        Fotografija korisnika
                        </span>
                        <input
                        class="file-input"
                        ref="fileInput"
                        type="file"
                        @input="onSelectFile"
                        >
                      </div>
                     <v-text-field
                              name="First name"
                              label="Ime"
                              v-model="form.firstName"
                              type="text"
                           ></v-text-field>
                            <v-text-field
                              name="Last name"
                              label="Prezime"
                              v-model="form.lastName"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              name="email"
                              label="Email"
                              v-model="form.email"
                              type="text"
                           ></v-text-field>
                            <v-text-field
                              name="username"
                              label="Username"
                              v-model="form.username"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              name="username"
                              label="Broj telefona"
                              v-model="form.phone"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              name="password"
                              label="Lozinka"
                              type="password"
                              v-model="form.password"
                           ></v-text-field>
                            <v-text-field
                              id="passwordConfirm"
                              name="passwordConfirm"
                              label="Potvrda lozinke"
                              type="password"
                              v-model="form.passwordConfirm"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="register">Dodaj</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
               <v-card class="users" >
                <v-card-title>Korisnici</v-card-title>
                <v-data-table
          
          :headers="headers"
          :items="desserts"
          disable-pagination
          :hide-default-footer="true"
        >
        <template v-slot:[`item.image`]="{ item }">
          <img :src="`/`+item.image" style="width: 50px; height: 50px; border-radius:50%; margin: 10px;" />
        </template>
          <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="dialogDeleteFun(item._id)"  small color="red" fab> <v-icon color="white">mdi-delete</v-icon></v-btn>
       
          </template>
        
        </v-data-table>
        <v-dialog
        v-model="dialogDelete"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Brisanje korisnika
          </v-card-title>
  
          <v-card-text class="pa-6">
             <strong> Da li ste sigurni da zelite da obrisete korisnika.Vracanje nece biti moguce!</strong>
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogDelete = false">
              <v-icon class="mr-1">mdi-close</v-icon>
              Odustani
            </v-btn>
            <v-btn
              color="red"
              dark
              @click="deleteItem()"
            >

            <v-icon color="white">mdi-delete</v-icon>
              OBRIŠI
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
            </v-card>
            </v-layout>
           
           </v-container>
           
      </v-main>
   </v-app>
</template>

<script>
import axios from "axios"
export default {
    data: () => ({
        imageData: null,
        dialogDelete : false,
        userId : null,
        headers: [
          { text: 'Slika', value: 'image', sortable: false },
          { text: 'Ime', value: 'firstName', sortable: false },
          { text: 'Prezime', value: 'lastName' , sortable: false},
          { text: 'Email', value: 'email', sortable: false },
          { text: "Akcije", value: "actions", sortable: false },

        ],
        desserts: [
        ],
      form : {
        image:null,
        firstName:'',
        phone:'',
        lastName:'',
        email:'',
        username:'',
        password:'',
        passwordConfirm:'',
      },
      errMsg:null
    }),
    mounted() {
        axios.post("/users/getUsers")
            .then(async response => {
               this.desserts = await response.data.users    
               console.log(this.desserts)
            })
            .catch(error => {
                //Set error message 
                console.log(error.data)
            })
    },
    methods: {
        
        async deleteItem(){
        
        let data = {
          "id": this.userId
        }
        await axios.post("/users/deleteUser", data)
        .then(async response => {
          console.log(response.data.message)
        })
      await axios.post("/users/getUsers")
            .then(async response => {
               this.desserts = await response.data.users    
               console.log(this.desserts)
            })
      this.dialogDelete = false
      
      },
        dialogDeleteFun(id){
            this.userId = id
            this.dialogDelete = true;
        },
        chooseImage () {
            this.$refs.fileInput.click()
        },
        onSelectFile () {
            const input = this.$refs.fileInput
            const files = input.files
            if (files && files[0]) {
                this.form.image =  files[0]
                const reader = new FileReader
                reader.onload = e => {
                this.imageData = e.target.result
                }
                reader.readAsDataURL(files[0])
                this.$emit('input', files[0])
            }
            console.log(this.form)
            },
        async register() {
            const formData = new FormData();
                formData.append("image", this.form.image )
                formData.append("firstName", this.form.firstName )
                formData.append("lastName", this.form.lastName )
                formData.append("email", this.form.email )
                formData.append("username", this.form.username )
                formData.append("phone", this.form.phone )
                formData.append("password", this.form.password )
                formData.append("passwordConfirm", this.form.passwordConfirm)
             axios.post("/users/register", formData ,
             {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(async response => {
               console.log(response.data) 
               this.errMsg = false;  
               this.form = {}
               this.imageData = null
               await axios.post("/users/getUsers")
               .then(async response => {
               this.desserts = await response.data.users    
               console.log(this.desserts)
            })
            })
            .catch(error => {
                //Set error message 
                this.errMsg = error.response.data.message
                window.scrollTo(0, 0);
            })
           
        },
    }
};
</script>


<style scoped>
.card1{
    width: 90%;
}
.users{
    width: 50%;
    height: auto;
}
  @media only screen and (max-width: 1205px) {
   
  .card1, .users{
    width: 100%;
  }
  .users{margin-top: 60px;}
  }
  .base-image-input {
  margin-bottom: 20px;
  display: block;
  width: 150px;
  text-align: center;
  height: 150px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #F0F0F0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #E0E0E0;
}
.file-input {
  display: none;
}
a{
  text-decoration: none;
}
</style>

