<template>
    <nav class="mb-15">

    <v-app-bar
    elevation="9"
    class="mb-5"
    fixed    
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <span class="font-weight-light">Admin</span>
        <span>Panel</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
         <router-link class="hidden-sm-and-down" to="/admin/dodajNekretninu" ><v-btn color="primary" class="mr-3"><v-icon style="cursor:pointer; " color="white" class="mr-5" >mdi-plus-box</v-icon>Dodaj nekretninu</v-btn> </router-link>
           <v-btn
        class="mr-3"
        @click="toggleDarkMode" 
        :value="darkMode" 
        elevation="5"
        icon
        >
        <v-icon>mdi-theme-light-dark</v-icon>
        </v-btn>
      <v-btn 
      @click="logout" 
       text
      class="mx-2"
      outlined
      
      >
      <span  class=".d-sm-none .d-md-flex mr-2">ODJAVI SE</span>
      <v-icon>mdi-logout</v-icon>
     
      </v-btn>
       
   
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      fixed    
      
    >
    <v-app-bar
    class="mb-4 bars"
    elevation="0"
      dark

    >
      <v-app-bar-nav-icon color="white" @click="drawer = false"></v-app-bar-nav-icon>

      <v-toolbar-title color="white">
        <span  class="font-weight-light">Admin</span>
        <span>Panel</span>
      </v-toolbar-title>

   
    </v-app-bar>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
        >
          <v-list-item v-for="link in links" :key="link.text" router :to="link.route">

            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    </nav>
</template>

<script>
export default {
     data: () => ({
       switch1: true,
      darkMode: false,
      drawer: false,
      group: null,
      links:[
        { icon: "mdi-home-city-outline" , text: "Nekretnine", route: "/admin/nekretnine"},
        { icon: "mdi-plus-box" , text: "Dodaj Nekretninu", route: "/admin/dodajNekretninu"},
        { icon: "fa-users" , text: "Korisnici", route: "/admin/korisnici"},


      ]
    }),

      methods: {
      toggleDarkMode: function () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        this.darkMode = !this.darkMode;
      },
      logout() {
        window.localStorage.removeItem("token"); 
        this.$router.push({ path: "/login" });
    },
    },

    computed: {
      switchLabel: function () {
        return this.darkMode ? 'light' : 'dark';
      }
    }
    
}
</script>

<style scoped>
a{
  text-decoration: none;
}

</style>