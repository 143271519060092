<template>
<div>

<div class="nekretnineFeed">
  
    <div class="center">
     
     <div class="nekretnine">
        <div class="sortBy">
        <h3>Search real estates</h3>
        <div class="hidden-md-and-down">
        <label for="sortBy">Sort:</label><br>
        <select name="sortBy" @change="search()" v-model="data.sort" class="sortSelect" id="">
          <option selected value="Najnovije">Newest</option>
          <option value="Najstarije">Oldest</option>
          <option value="Najjeftinije">The cheapest</option>
          <option value="Najskuplje">The most expensive</option>
        </select>
        </div>
        </div>
        <div class="filteriFild 
         hidden-lg-and-up"
         @click="activeFilters = !activeFilters"
         >Filters</div>
        <div class="mobileSeach 
          hidden-lg-and-up"
         :class="{activeFilters: activeFilters}"
          >
          <div class="select">
            <label for="purpose">Purpose:</label>
            <select v-model="data.purpose" name="purpose">
              <option default selected value="">Choose</option>
              <option value="Prodaja">Buy</option>
              <option value="Izdavanje">Rent</option>
              <option value="Stan na dan">One day apartment</option>
            </select>
          </div>
          <div class="select">
              <label for="town">Town:</label>
            <select v-model="data.town" name="town">
              <option default selected value="">Choose</option>

              <option value="Andrijevica">Andrijevica</option>
              <option value="Bar">Bar</option>
              <option value="Berane">Berane</option>
              <option value="Bijelo Polje">Bijelo Polje</option>
              <option value="Budva">Budva</option>
              <option value="Cetinje">Cetinje</option>
              <option value="Danilovgrad">Danilovgrad</option>
              <option value="Gusinje">Gusinje</option>
              <option value="Herceg Novi">Herceg Novi</option>
              <option value="Kolasin">Kolašin</option>
              <option value="Kotor">Kotor</option>
              <option value="Mojkovac">Mojkovac</option>
              <option value="Niksic">Nikšić</option>
              <option value="Petnjica">Petnjica</option>
              <option value="Plav">Plav</option>
              <option value="Pluzine">Plužine</option>
              <option value="Pljevlja">Pljevlja</option>
              <option value="Podgorica">Podgorica</option>
              <option value="Rozaje">Rožaje</option>
              <option value="Savnik">Šavnik</option>
              <option value="Tivat">Tivat</option>
              <option value="Tuzi">Tuzi</option>
              <option value="Ulcinj">Ulcinj</option>
              <option value="Zabljak">Žabljak</option>
            </select>
          </div>
          <div class="select">
              <label for="type">Type:</label>
            <select  v-model="data.category" name="type">
              <option default selected value="">Choose</option>

              <option value="Stan">Apartment</option>
              <option value="Kuca">House</option>
              <option value="Zemljiste">Land</option>
              <option value="Poslovni prostor">Business premises</option>
              <option value="Hotel">Hotel</option>
              <option value="Garaza">Garage</option>
              <option value="Ugostiteljski objekat">Catering facility</option>
            </select>
          </div>
          <div class="select">
              <label for="status">Status:</label>
            <select v-model="data.status" name="status">
              <option default selected value="">Choose</option>

              <option value="Namjesteno">Furnished</option>
              <option value="Nenamjesteno">Unfurnished</option>
            </select>
          </div>
          <div class="select">
              <label for="structure">Structure:</label>
            <select v-model="data.type" name="structure">
              <option default selected value="">Choose</option>

              <option value="Garsonjera">Studio apartment</option>
              <option value="Jednosoban">One bedroom</option>
              <option value="Dvosoban">Two bedroom</option>
              <option value="Trosoban">Three bedroom</option>
              <option value="Cetvorosoban">Four bedroom</option>
              <option value="Petosoban">Five bedroom</option>
            </select>
          </div>
            <div class="select">
              <label for="price">Price:</label>
             <section class="priceDrop" >

              <input  v-model="data.priceMin" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="From:">
              <input  v-model="data.priceMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="To:">
             </section>

            
          </div>
          <div class="select" >
             <label for="purpose">Square footage:</label>
              <section class="priceDrop" >

              <input v-model="data.sizeMin" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="From:">
              <input v-model="data.sizeeMax" style="padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="To:">
              </section>
          </div>
          <div><div @click="search()"   class="searchBtn seachmob">Search</div></div>
        </div>
        <div  class="searchField hidden-md-and-down"  style="  border-bottom-left-radius: 0px;
  border-top-bottom-radius: 0px;
">
          <div class="select">
            <label for="purpose">Purpose:</label>
            <select  v-model="data.purpose" name="purpose">
              <option default selected value="">Choose</option>
              <option value="Prodaja">Buy</option>
              <option value="Izdavanje">Rent</option>
              <option value="Stan na dan">One day apartment</option>
            </select>
          </div>
          <div class="select" >
              <label for="town">Town:</label>
            <select v-model="data.town" name="town">
              <option default selected value="">Choose</option>

              <option value="Andrijevica">Andrijevica</option>
              <option value="Bar">Bar</option>
              <option value="Berane">Berane</option>
              <option value="Bijelo Polje">Bijelo Polje</option>
              <option value="Budva">Budva</option>
              <option value="Cetinje">Cetinje</option>
              <option value="Danilovgrad">Danilovgrad</option>
              <option value="Gusinje">Gusinje</option>
              <option value="Herceg Novi">Herceg Novi</option>
              <option value="Kolasin">Kolašin</option>
              <option value="Kotor">Kotor</option>
              <option value="Mojkovac">Mojkovac</option>
              <option value="Niksic">Nikšić</option>
              <option value="Petnjica">Petnjica</option>
              <option value="Plav">Plav</option>
              <option value="Pluzine">Plužine</option>
              <option value="Pljevlja">Pljevlja</option>
              <option value="Podgorica">Podgorica</option>
              <option value="Rozaje">Rožaje</option>
              <option value="Savnik">Šavnik</option>
              <option value="Tivat">Tivat</option>
              <option value="Tuzi">Tuzi</option>
              <option value="Ulcinj">Ulcinj</option>
              <option value="Zabljak">Žabljak</option>
            </select>
          </div>
          <div class="select">
              <label for="type">Type:</label>
            <select v-model="data.category" name="type">
              <option default selected value="">Choose</option>

              <option value="Stan">Apartment</option>
              <option value="Kuca">House</option>
              <option value="Zemljiste">Land</option>
              <option value="Poslovni prostor">Business premises</option>
              <option value="Hotel">Hotel</option>
              <option value="Garaza">Garage</option>
              <option value="Ugostiteljski objekat">Catering facility</option>

            </select>
          </div>
          <div class="select">
              <label for="status">Status:</label>
            <select v-model="data.status" name="status">
              <option default selected value="">Choose</option>

              <option value="Namjesteno">Furnished</option>
              <option value="Nenamjesteno">Unfurnished</option>
            </select>
          </div>

        </div>
         <div class="searchField hidden-md-and-down" style="  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
">
          <div class="select">
              <label for="structure">Structure:</label>
            <select v-model="data.type" name="structure">
              <option default selected value="">Choose</option>

              <option value="Garsonjera">Studio apartment</option>
              <option value="Jednosoban">One bedroom</option>
              <option value="Dvosoban">Two bedroom</option>
              <option value="Trosoban">Three bedroom</option>
              <option value="Cetvorosoban">Four bedroom</option>
              <option value="Petosoban">Five bedroom</option>
            </select>
          </div>
            <div class="select">
              <label for="price">Price:</label>
             <section class="priceDrop" >

              <input v-model="data.priceMin" style="width:110px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="From:"><span style="color:black">-</span>
              <input v-model="data.priceMax" style="width:110px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="To:">
             </section>

            
          </div>
          <div class="select" >
             <label for="purpose">Square footage:</label>
              <section class="priceDrop" >

              <input v-model="data.sizeMin" style="width:110px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="From:"><span style="color:black">-</span>
              <input v-model="data.sizeMax" style="width:110px;padding:6px;border-bottom:1px solid #919191" type="number" min="0" placeholder="To:">
              </section>
          </div>
          <div><div @click="search()"   class="searchBtn">Search</div></div>
        </div>
        <div id="srcScr"></div>
          <div class="sortMobile hidden-lg-and-up" >
   <label for="sortBy" >Sort:</label><br>
   <select name="sortBy" @change="search()" v-model="data.sort"  class="sortSelect" id="">
          <option selected value="Najnovije">Newest</option>
          <option value="Najstarije">Oldest</option>
          <option value="Najjeftinije">The cheapest</option>
          <option value="Najskuplje">The most expensive</option>
        </select>
        </div>
        <div class="progres" v-if="progress == true">
        <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    </div>
  
     </div>
     </div>
   
   <div id="scrollTO" style="margin-bottom:20px"></div>
   <center>
      <h2 v-if="findErr" style=" margin-top: 140px;">No results.</h2>
    </center>
    <main v-if="progress == false">
   
    <div class="cards">
      
      <div v-for="item in items" :key="item._id"   class="card">
        <router-link :to="`/en/realestate/`+item._id">
       <div class="cardImage">
        <img :src="'/'+item.images[0]" alt="nekretnina">
       </div>
       <div class="cardContent">
        <div class="hedCard">
          <div class="rent">
        <span class="price">€ {{item.price }}</span> <br>
        </div><br>
        <div v-if="item.purpose == 'Prodaja'"  class="category">
           Selling
        </div>
        <div v-if="item.purpose == 'Izdavanje'" style="background-color:black" class="category">
           Renting
        </div>
        <div v-if="item.purpose == 'Stan na dan'" style="background-color:red" class="category">
          One day apartment
        </div>
        </div>
       <h4 style="  font-weight: bold; -webkit-font-weight: bold; margin-top:7px">{{item.nameEng}}</h4>
        <p style="color:grey" >{{item.town}}</p>

        <hr>
        <div class="cardFooter">
          <div v-if="item.roomsNum" class="bed" >
            <img src="/bedFin.png" alt=""> {{ item.roomsNum + "  rooms"}}
          </div>
          <div class="bed" v-if="item.bathroomsNum && Number(item.bathroomsNum) > 0">
            <img src="/bathroom.png" alt=""> {{ item.bathroomsNum + "   bathrooms"}}
          </div>
          <div class="bed">
            <img src="/maximize.png" alt=""> {{ item.size + " m2" }}
          </div>
        </div>
      </div>
    </router-link>
      </div>
    
    </div>
  </main>
    <!-- <div class="paginationDiv">
    <div v-if="page == 1" class="pagination">
      <v-icon @click="changePage(page-1)" class="arrow">fa-chevron-left</v-icon>
      <div @click="changePage(page)" class="paginationNum active">{{page}}</div>
      <div @click="changePage(page+1)" class="paginationNum">{{page+1}}</div>
      <div @click="changePage(page+2)" class="paginationNum">{{page+2}}</div>
      <div class="dots">...</div>
      <div @click="changePage(lastPage)" class="paginationNum">{{lastPage}}</div>
      <v-icon @click="changePage(page+1)" class="arrow">fa-chevron-right</v-icon>

    </div>
    </div> -->

    <pagination
      :totalPages="lastPage"
      :perPage="9"
      :currentPage="page"
      @pagechanged="onPageChange"
    />
    
   



   </div>
   </div>
  
</template>
<script>
import Pagination from './PaginationCom.vue'
// import apiPost from '../../services/service'
import axios from 'axios'

export default {
  components: {
    Pagination
  },
    data () { 
      return{
        activeFilters:false,
        findErr:false,
      progress:false,
      items: [],
      data:{
        category:"",
        town:"",
        purpose:"",
        status:"",
        type:"",
        priceMin:"",
        priceMax:"",
        sizeMin:"",
        sizeMax:"",
        sort:"Najnovije"
        ,
        roomsNum:'',
        bathroomsNum:''
      },
      page:1,
      pagination:[],
      lastPage:1
      }
    },

    async mounted () {
        const query = this.$route.query
       //javascript empty object
        // function isEmpty(object) {
        //   return Object.keys(object).length === 0;
        // }
       
      try {
        // if(!isEmpty(query)) this.data = query
        if(query.category) this.data.category = await query.category
        if(query.town) this.data.town = query.town
        if(query.purpose) this.data.purpose = await query.purpose
        if(query.status) this.data.status = query.status
        if(query.type) this.data.type = query.type
        if(query.priceMin) this.data.priceMin = query.priceMin
        if(query.priceMax) this.data.priceMax = query.priceMax
        if(query.sizeMin) this.data.sizeMin = query.sizeMin
        if(query.sizeMax) this.data.sizeMax = query.sizeMax
        if(query.sort) this.data.sort = query.sort
   
        // Simple GET request using axios
        //geting data

       await axios.post('/posts/get', query, {
        headers: {
            'Content-Type': 'application/json',
        }
      })
        .then(async response => {
          this.items = await response.data.posts
          this.items.forEach(item => {
            let price = item.price;
            let newP =  price.toLocaleString('de-DE', { 
                style: 'currency', 
                currencyDisplay: 'code', 
                currency: 'EUR' 
             });
            item.price = newP.slice(0,-3)
          })
          const page = await response.data.page
          this.page = parseInt(page)
          this.lastPage = await response.data.lastPage
       })
      } catch (error) {
        console.log(error.message)
      }
      if(this.items.length < 1) this.findErr = true

       
    },
    methods:{
     async onPageChange(page) {
      //loading anim
      this.progress = true
      //scroll to top
      // document.getElementById("srcScr").scrollIntoView();

      let searchData = await this.$route.query
      searchData.page = await  page
      
      //cleaning query
      this.$router.push({ path: '/en/realestate', query: {} })
      //starting new
      this.$router.push({ path: '/en/realestate', query: searchData })

      //change page variable
      this.page = page;

      //GETTING DATA INTO FORM DATA


      //geting data
      await axios.post('/posts/get', searchData, {
        headers: {
            'Content-Type': 'application/json',
        }
      }).then(response => {
        this.items = response.data.posts
        this.items.forEach(item => {
            let price = item.price;
            let newP =  price.toLocaleString('de-DE', { 
                style: 'currency', 
                currencyDisplay: 'code', 
                currency: 'EUR' 
             });
            item.price = newP.slice(0,-3)
          })
      })
     
      
      //how long action last
      setTimeout(() => {
              this.progress = false}, 1000)
    },
      async search(){
        this.findErr = false
        const Data = this.data
        // with query, resulting 
        Data.page = 1
        this.page = 1
        this.$router.push({ path: '/en/realestate', query: {} })
        this.$router.push({ path: '/en/realestate', query: Data })
        this.progress = true
        try{
        await axios.post('/posts/get', Data, {
        headers: {
            'Content-Type': 'application/json',
        }
      }).then(async response => {
          this.items = await response.data.posts
          this.items.forEach(item => {
            let price = item.price;
            let newP =  price.toLocaleString('de-DE', { 
                style: 'currency', 
                currencyDisplay: 'code', 
                currency: 'EUR' 
             });
            item.price = newP.slice(0,-3)
          })
          this.lastPage = await response.data.lastPage

          
       })
      }catch (error) {
        this.items = []
        if(this.items.length < 1) this.findErr = true
        console.log(error.message)
      }

       setTimeout(() => {
              this.progress = false
              if(this.items.length < 1) this.findErr = true
            
            }, 1000)
      },

    }
}
</script>


<style scoped>
a{
  text-decoration: none;
}
.mobileSeach .select , .mobileSeach select{
  width: 100%;
  padding-left: 0px;
}
.mobileSeach{
  border-radius: 20px;
  padding: 15px;
  transition: 0.4s ease-in-out;
  border: 1px solid rgb(212, 212, 212);
  height: 0px;
  opacity: 0;
  overflow: hidden;
}
.filteriFild{
  margin-bottom: 5px;
  width: 100%;
  padding: 20px;
  background: #e7e6f8ba;
  border: 1px solid rgba(33,111,253,255);
  border-radius: 10px;

}
.activeFilters{
  height: 760px;
  opacity: 1;
}
.progres{
  margin-top: 40px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
}
.mobileSeach .select {
  margin-bottom: 20px;
}
.mobileSeach .select input,mobileSeach .searchBtn{
  width: 100%;
}
.nekretnineFeed{
 margin-top: 80px;
 padding-top: 40px;
}
@media only screen and (max-width:850px){
  .nekretnineFeed{
 margin-top: 40px;
 padding-top: 40px;
}
}
.search{
  grid-area: header;
  justify-content: center;
}
.center{
  box-sizing: border-box;
  display: flex;
  justify-content: center;

}
.nekretnine{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 1160px;
}
main{
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto,60px)) 1fr;
  grid-gap: 40px;
  padding: 60px 0px;
}
.cards{
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns:  repeat(12, minmax(auto,60px));
  grid-gap: 40px;
  
}
.card{
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.card:hover{
  transform: translateY(-7px);
}
.cardImage{
  width: 100%;
  padding-top:55% ;
  overflow: hidden;
  position: relative;
}
.cardImage img{
  border-top-left-radius: 12px;
  border-top-right-radius: 6px;
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cardContent{
  padding: 20px 20px 20px 20px;
}
select{
  cursor: pointer;
}
.price{
  color: rgba(33,111,253,255);
  font-weight: bold; -webkit-font-weight: bold;
  font-size: 22px;
}
.location{
  font-weight: bold; -webkit-font-weight: bold;
  color: #919191;
}
.cardFooter{
  color: #919191;
  font-weight: bold; -webkit-font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.cardFooter img{
  margin-right: 5px;

}
.hedCard{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
  .category{ border-radius: 4px;

  background:rgba(33,111,253,255) ;
  pad: 5px;
  text-align: center;
  width: 100px;
  height: 30px;
  padding-top: 2px;
  color: white;
}
.rent{
  color: grey;
}
@media only screen and (max-width:1285px){
  .nekretnine{
    width: 91%;
  }
  .searchField{
    display: none;
  }
}
@media only screen and (max-width:1000px){
  
  .card{
    grid-column-end: span 6;
  }
  .nekretnine{
    width: 92%;
  }
   .searchField{
    display: none;
  }
}
@media only screen and (max-width:700px){
  main{
    grid-gap: 20px;
  }
  .card{
    grid-column-end: span 12;
  }
  .nekretnine{
    width: 90%;
  }
   .searchField{
    display: none;
  }
}
@media only screen and (max-width:500px){
  main{
    grid-template-columns:10px repeat(6, 1fr) 10px;
    grid-gap: 10px;
  }
  .nekretnine{
    width: 90%;
  }
   .searchField{
    display: none;
  }
  .cards{
    grid-column: 2 / span 6;
    grid-template-columns:repeat(6, 1fr);
    grid-gap: 20px;

  }
  .card{
    grid-column-end: span 6;
  }
}
.sortBy{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
   margin-top: 40px;
  margin-bottom: 20px;
  
}
.sortBy h3{
  font-size: 29px;
  font-weight: 700;
}

@media only screen and (max-width:1000px){
.sortBy h3{
  font-size: 24px;
  font-weight: 700;
}
}
.searchField{
  width: 100%;
  height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}
.searchBtn{
  width: 300px;
  height: 55px;
  padding: 14px 6px;
  cursor: pointer;
  color: white;
  background: rgba(33,111,253,255);
  border-radius: 8px;
  margin: 10px 0px;
  text-align: center;
  padding-right: 20px;
  margin-top: 23px;
  margin-right: 10px;
}
.select{
  color: grey;
  flex-direction: column;
  padding-left: 26px;
}
select , option{
  border-bottom: 1px solid rgb(178, 178, 178);
  /* border-radius: 5px; */
  width: 234px;
  padding: 6px 2px;
}
.searchField div{
  display: flex;
  justify-content: center;
  width: 100%;

}
input{
  color: black;
}
.sortSelect{
  border: 1px solid rgb(155, 155, 155);
  padding: 1px 15px;
  width: 240px;
  height: 45px;
  background: white;
  color: grey;
  border-radius: 10px;
  
 
}
.sortMobile{
  margin-top: 20px;
}

.filters{
  width: 100%;
  background: white;
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 10px;
  padding: 16px 10px 10px 10px ;
  height: 100px;
}
.filters div{
  width: 100%;
  padding-left: 30px;
  border-right: 1px solid #E0DEF7;
}
.filters select{
  font-weight: 700;
  padding: 10px;
}
.filters label{
  color: rgba(0, 22, 25, 0.7);
  padding-left: 10px;
}

.pagination{
  font-weight: 700;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
.dots{
  margin-right: 12px;
  margin-top: 18px;
}
.paginationNum{
  padding: 4px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
   color: rgba(0, 22, 25, 0.7);
  border-radius: 30px;
  cursor: pointer;
  margin-right: 9px;
}
.arrow{
  font-size:16px;
  cursor: pointer;
  margin: 14px;
}
.sort{
  width: 100%;
  background: rgb(255, 255, 255);
  color: grey;
  border-radius: 10px;
  padding: 8px 10px;
  margin-bottom: 25px;
}
.active{
  background-color: #000929;
  color: white;
}
.seachmob{
  width: 100%;
}
</style>