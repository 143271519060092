<template>
    <div class="homeNav">

    <!--DESKTOP HEADER -->
    <div id="header">
      <div class="linksLogo">
        <div class="logo" style="margin-right:35px">
          <a href="/">
        <img style="margin-top:-5px" width="100px" src="/trust.png" alt="logo">
          </a>
      </div>
      <nav style="margin-top:16px;">
        <ul>
            <router-link  active-link="activeMob" exact to="/en">Home</router-link>
            <router-link   active-link="activeMob"  to="/en/realestate">Real Estate</router-link>
            <router-link   active-link="activeMob" to="/en/consulting">Consulting</router-link>
            <router-link   active-link="activeMob" exact to="/en/contact">Contact</router-link>
        </ul>
      </nav>
      </div>
      <div>

        
        <v-select
        @change="translate"
        style="width: 200px"
        class="mt-5"
        :items="items"
        item-text="name"
        item-value="last"
        return-object
        v-model="defaultSelected"
         >
   <template v-slot:selection="{ item }">
      <img style="border: 1px solid rgb(205, 205, 205);" width="35px" class="mr-2" :src="item.image">{{ item.name }}
   </template>

   <template  v-slot:item="{ item }" >
   
      <img  style="border: 1px solid rgb(205, 205, 205);" width="35px" class="mr-5" :src="item.image">{{ item.name }}
 
    </template>
    </v-select>
      </div>
    </div>

    <!--MOBILE HEADER -->
    <div id="headerMobile">
 <v-app-bar 
  color="#FFFFFF"
  fixed
  
 class="mb-11"  >
   <a href="/">
      <v-toolbar-title >        <img width="55px" src="/trust.png" alt="logo">
</v-toolbar-title>
   </a>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color="#000000" @click="drawer = true"></v-app-bar-nav-icon>


    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      class="mobileNav"
    >
     <v-icon class="arrow" @click="drawer = false" color="primary">fa-arrow-left-long</v-icon>

     <div class="navMenuMob">
        <div style="display:flex; justify-content:center">
          <a href="/">
            <img width="105px" src="/trust.png" alt="logo">
          </a>
        </div>
      </div>
      <div class="linksMobile">
            <router-link active-link="activeMob" exact style="border-top: 1px solid rgb(181, 181, 181)" to="/en">Home</router-link>
            <router-link active-link="activeMob"  to="/en/realestate">Real Estate</router-link>
            <router-link   active-link="activeMob" to="/en/consulting">Consulting</router-link>
            <router-link active-link="activeMob" exact to="/en/contact">Contact</router-link>
      </div>
      <center>
      <v-select
        style="width: 200px"
        @change="translate"
        class="mt-5"
        :items="items"
        item-text="name"
        item-value="last"
        return-object
        v-model="defaultSelected"
         >
   <template v-slot:selection="{ item }">
      <img style="border: 1px solid rgb(205, 205, 205);" width="35px" class="mr-2" :src="item.image">{{ item.name }}
   </template>

   <template  v-slot:item="{ item }" >
   
      <img  style="border: 1px solid rgb(205, 205, 205);" width="35px" class="mr-5" :src="item.image">{{ item.name }}
 
    </template>
    </v-select>
  </center>
      
    </v-navigation-drawer>
    </div>

    </div>
</template>


<script>
// import FooterComponent from '@/components/home/Footer.vue'

export default {
    name: "HeaderComponent",
    // components:{FooterComponent},
    data () {
        return {
          defaultSelected: {
      name: "English",
      link:"/en"
    },
          items: [
      { name: 'Crnogorski', image: '/zastavamne.jpeg' , link:"/"},
      { name: 'English', image: '/zastavaamerika.jpeg',link:"/en"},
      { name: 'Русский', image: '/zastavarusija.jpeg', link:"/rus"},
     ],
          isActiveContact: false,
          isActiveNekretnine: false,
          isActivePocetna: false,
        select: null,
    countries: [
      {
        name: "Crnogorski",
        flag: "em-flag-ad"
      },
      {
        name: "English",
        flag: "em-flag-ae"
      },
       {
        name: "Русский",
        flag: "em-flag-ae"
      },
    
   ],
           drawer: false,
           group: null,
        };
      },
      methods: {
        translate(){
          this.$router.push(this.defaultSelected.link)

        }
      },
      mounted() {
      },
}
</script>


<style scoped>
.v-list-item {
  padding: 0px !important;
}
.activeDef{
  background: white;
    opacity: 1;
}
.activeMob{
  background: rgba(33,111,253,255);
  color: white;
}
.navMenuMob{
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.linksMobile{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.linksMobile a{
  border-bottom: 1px solid rgb(181, 181, 181);
  padding: 10px;
}
.arrow{
  float: right;
  margin: 15px 20px;
}
a{
  text-decoration: none;
}
nav{
  margin-top: 10px;
}
nav ul a{
  border-radius: 4px;

margin-right: 18px;
color: #000929;
padding: 10px;
transition: 0.3s ease-in-out;

}
.linksLogo{
  margin-top: 7px;

  display: flex;
  flex-direction: row;
}
 #header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  height: 80px;
  display: flex;
  padding-left: 70px;
  padding-right: 70px;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  /* backdrop-filter: blur(40px); */
  border-bottom: 1.5px solid #F0EFFB;
  
 }
 nav ul a:hover{
  
  background: rgba(33,111,253,255);
  color:white;
 }
 .activeLink{
  border-radius: 4px;
  padding: 10px;
  background: #e7e6f8ba;
  color: rgba(33,111,253,255);
 }
  @media only screen and (max-width: 850px) {
  #header{
    display: none;
  }
}
 @media only screen and (min-width: 851px) {
  #headerMobile{
    display: none;
  }
}

</style>