<template>
    <div id="admin">
    <div class="spacer"></div>
    <NavBar />
      <router-view/>
    <div class="space"></div>
    <FooterComponent />
    </div>
</template>


<script>
import NavBar from '@/components/Admin/Layout/NavBar'
import FooterComponent from '@/components/Admin/Layout/FooterComponent'

export default {
    name: "AdminPanel",
    components: {
      NavBar,
      FooterComponent
    },

}
</script>


<style scoped>
#admin{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.space{
  height: 170px;
  width: 1px;
  margin-top: 40px;
  margin-bottom: 50px;
}
.spacer{
  height: 20px;
  width: 1px;
}
</style>