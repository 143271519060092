<template>
  <ul class="pagination">
    <li class="pagination-item">
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
      >
        <v-icon>fa-angle-double-left</v-icon>
      </button>
    </li>

    <li class="pagination-item">
      <button
      @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        type="button"
      >
        <v-icon>fa-arrow-left</v-icon>
      </button>
    </li>

    <!-- Visible Buttons Start -->

   
   <li
    class="pagination-item"
      v-for="page in pages"
      :key="page.name"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li>


    <!-- Visible Buttons End -->

    <li class="pagination-item">
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
      >
      <v-icon>fa-arrow-right</v-icon>
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
      >
      <v-icon>fa-angle-double-right</v-icon>

      </button>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },    
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  }
  ,
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages && this.totalPages != 2) {
        return this.totalPages - 2;
      }
     
      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    }
  }
};
</script>


<style>
.active{
  background-color: #000929;
  color: white;
}
.pagination-item{
  padding: 4px;
  width: 45px;
  height: 45px;
  background-color: rgb(255, 255, 255);
   color: rgba(0, 22, 25, 0.7);
  border-radius: 30px;
  margin-right: 9px;
}
.pagination-item button{
  width: 45px;
  height: 45px;
  border-radius: 30px;
  margin-left: -5px;
  margin-top: -5px;
}
.pagination{
  font-weight: 700;
  margin-top: 45px;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
}
.dots{
  margin-right: 12px;
  margin-top: 18px;
}
</style>